// React
import React, { lazy, Suspense } from 'react'
import { Switch, Route } from 'react-router-dom'
// Context
import { UserProvider } from './AppContext'
// Material UI
import { CircularProgress } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
// Components
import ErrorBoundary from '../Utils/ErrorBoundary'
import PrivateRoute from './PrivateRoute'
import ServerRoute from './ServerRoute'

const ChangePassword = lazy(() => import('../Register/ChangePassword'))
const DashboardQueryHelper = lazy(() => import('../Dashboard/DashboardQueryHelper'))
const NewMembershipContainer = lazy(() => import('../Dashboard/NewMembership/NewMembershipContainer'))
const AcceptSubscriptionContainer = lazy(() => import('../Profile/Plans/ApproveSubscription'))
const UpsertCompleteFullService = lazy(() => import('../CompleteFullService/UpsertCompleteFullService'))
const CaptureOrderContainer = lazy(() => import('../Profile/Plans/CaptureOrder'))
const CreateBranchContainer = lazy(() => import('../CreateBranch/CreateBranchContainer'))
const CreateGroupContainer = lazy(() => import('../CreateGroup/CreateGroupContainer'))
const CreatePersonalQueryHelper = lazy(() => import('../CreatePersonal/CreatePersonalQueryHelper'))
const CreateProviderQueryHelper = lazy(() => import('../CreateProvider/CreateProviderQueryHelper'))
const CreateServiceQueryHelper = lazy(() => import('../CreateService/CreateServiceQueryHelper'))
const CreateScheduledService = lazy(() => import('../CreateScheduledService/CreateSSQueryHelper'))
const CreateVehicleQueryHelper = lazy(() => import('../CreateVehicle/CreateVehicleQueryHelper'))
const EditCompletedServiceQueryHelper = lazy(() => import('../CompleteService/EditCompletedServiceQueryHelper'))
const EditDocumentQueryHelper = lazy(() => import('../EditDocument/EditDocumentQueryHelper'))
const EditProviderQueryHelper = lazy(() => import('../CreateProvider/EditProviderQueryHelper'))
const EmailValidation = lazy(() => import('../Register/EmailValidation'))
const Footer = lazy(() => import('../Footer/Footer'))
const Groups = lazy(() => import('../Groups/Groups'))
const Branches = lazy(() => import('../Branches/Branches'))
const Header = lazy(() => import('../Header/Header'))
const Landing = lazy(() => import('../Landing/Landing'))
const Login = lazy(() => import('../Login/Login'))
const Personal = lazy(() => import('../Personal/Personal'))
const Privacy = lazy(() => import('../Privacy/Privacy'))
const Profile = lazy(() => import('../Profile/ProfileQueryHelper'))
const Providers = lazy(() => import('../Providers/Providers'))
const Register = lazy(() => import('../Register/Register'))
const ServicesContainer = lazy(() => import('../Services/ServicesContainer'))
const ShowPersonal = lazy(() => import('../ShowPersonal/ShowPersonal'))
const ShowReminders = lazy(() => import('../Reminders/ShowReminders'))
const ShowService = lazy(() => import('../Services/ShowService'))
const ShowSS = lazy(() => import('../Services/ShowSS'))
const ShowVehicle = lazy(() => import('../ShowVehicle/ShowVehicle'))
const Terms = lazy(() => import('../Terms/Terms'))
const Vehicle = lazy(() => import('../Vehicle/Vehicle'))
const EditCompletedScheduledServiceQueryHelper = lazy(() => import('../CompleteScheduledService/EditCompletedScheduledServiceQueryHelper'))
const ReportQueryHelper = lazy(() => import('../Reports/ReportQueryHelper'))

const appStyles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh'
  },
  content: {
    flex: '1 1 auto',
    background: theme.palette.background.default,
    display: 'flex'
  },
  loading: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    background: '#333333D9',
    color: '#fff'
  }
})

const AppWithContext = () => {
  return (
    <UserProvider>
      <AppWithStyles />
    </UserProvider>
  )
}

const App = (props) => {
  const { classes } = props

  React.useEffect(() => {
    const script = document.createElement('link')
    script.href = 'https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600|Source+Sans+Pro:300,400,600&display=swap'
    script.rel = 'stylesheet'
    document.body.appendChild(script)

    const css = document.createElement('link')
    css.rel = 'preconnect'
    css.href = 'index.css'
    css.rel = 'stylesheet'
    document.body.appendChild(css)
  }, [])

  // IF NOT LOGGED THEN LANDING ELSE VEHICLE?
  const Loading = () => {
    return (
      <div className={classes.loading}>
        <CircularProgress open />
      </div>
    )
  }

  return (
    <ErrorBoundary>
      <Suspense fallback={<Loading />}>
        <div className={classes.root}>
          <Switch>
            <Route exact path='/' component={Landing} />
          </Switch>
          <Header />
          <div className={classes.content}>
            <Switch>
              <PrivateRoute exact path='/vehiculos' component={Vehicle} />
              <PrivateRoute exact path='/agregar-sucursal' component={CreateBranchContainer} />
              <PrivateRoute exact path='/agregar-grupo' component={CreateGroupContainer} />
              <PrivateRoute exact path='/agregar-personal' component={CreatePersonalQueryHelper} />
              <PrivateRoute exact path='/agregar-proveedor' component={CreateProviderQueryHelper} />
              <PrivateRoute exact path='/agregar-servicio' component={CreateServiceQueryHelper} />
              <PrivateRoute exact path='/agregar-servicio-programado' component={CreateScheduledService} />
              <PrivateRoute exact path='/agregar-vehiculo' component={CreateVehicleQueryHelper} />
              <PrivateRoute exact path='/completar-servicio' component={UpsertCompleteFullService} />
              {/* <PrivateRoute exact path='/completar-servicio' component={CompleteService} /> */}
              {/* <PrivateRoute exact path='/completar-servicio-grupal' component={CompleteScheduledService} /> */}
              <PrivateRoute exact path='/editar-documento' component={EditDocumentQueryHelper} />
              <PrivateRoute exact path='/editar-servicio' component={EditCompletedServiceQueryHelper} />
              <PrivateRoute exact path='/editar-servicio-grupal' component={EditCompletedScheduledServiceQueryHelper} />
              <PrivateRoute exact path='/sucursales' component={Branches} />
              <PrivateRoute exact path='/sucursal' component={CreateBranchContainer} edit />
              <PrivateRoute exact path='/editar-sucursal' component={CreateBranchContainer} edit />
              <PrivateRoute exact path='/grupo' component={CreateGroupContainer} edit />
              <PrivateRoute exact path='/editar-grupo' component={CreateGroupContainer} edit />
              <PrivateRoute exact path='/grupos' component={Groups} />
              <PrivateRoute exact path='/perfil' component={Profile} />
              <PrivateRoute exact path='/store' component={Profile} />
              <PrivateRoute exact path='/personal' component={Personal} />
              <PrivateRoute exact path='/proveedor' component={EditProviderQueryHelper} />
              <PrivateRoute exact path='/proveedores' component={Providers} />
              <PrivateRoute exact path='/recordatorios' component={ShowReminders} />
              <PrivateRoute exact path='/servicio' component={ShowService} />
              <PrivateRoute exact path='/servicio-programado' component={ShowSS} />
              <PrivateRoute exact path='/servicios' component={ServicesContainer} />
              <PrivateRoute exact path='/usuario' component={ShowPersonal} />
              <PrivateRoute exact path='/vehiculo' component={ShowVehicle} />
              <PrivateRoute exact path='/dashboard' component={DashboardQueryHelper} />
              <PrivateRoute exact path='/nueva-membresia' component={NewMembershipContainer} />
              <PrivateRoute exact={false} path='/reportes/:reporte?/:auto?' component={ReportQueryHelper} />
              <Route exact path='/cambiar-contrasena' component={ChangePassword} />
              <Route exact path='/login' component={Login} />
              <Route exact path='/registro' component={Register} />
              <Route exact path='/validar-correo' component={EmailValidation} />
              <Route exact path='/subscripcion-aceptada' component={AcceptSubscriptionContainer} />
              <Route exact path='/orden-pagada' component={CaptureOrderContainer} />
              <Route exact path='/condiciones' component={Terms} />
              <Route exact path='/privacidad' component={Privacy} />
            </Switch>
          </div>
          <Footer />
        </div>
      </Suspense>
    </ErrorBoundary>
  )
}

const AppWithStyles = withStyles(appStyles)(App)

export default AppWithContext
